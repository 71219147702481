@forward '@ey-xd/motif-react/styles.scss';

.customMotifTable {
  --ag-background-color: #1a1a24 !important;
  --table-header--color: #bfbfc8;
  --ag-header-foreground-color: #bfbfc8;
  --table-header-container--bg-color: #2e2e38;
  --table-header--bg-color: #2e2e38;

  --table-header--border-width: 0;
  --table--border-style: none;
  --table-header-container--border-style: none;
  --table-header-hover--border-style: none;

  --table-row--color: #dedee2;
  --table-odd-row--bg-color: #1a1a24;
  --table-even-row--bg-color: #1a1a24;
  .ag-cell:hover {
    transition: background-color 0.5s ease-in-out;
    background-color: #2e2e38 !important;
  }
  .disabledRow {
    --table-cell--color: #41414b !important;
    --table-cell-hover--bg-color: #1a1a24 !important;
    --table-cell-focus--bg-color: #1a1a24 !important;
    .ag-cell:hover {
      color: #41414b !important;
      background-color: #1a1a24 !important;
    }
  }
  .ag-filter {
    background-color: #23232f !important;
    color: #a4a3b1 !important;
  }
  .ag-header-cell {
    font-size: 1rem;
  }
}

.motif-header {
  .motif-header-vertical-navigation-open-button {
    button {
      align-items: center !important;
      justify-content: center !important;
    }
  }
  .motif-header-logo {
    margin-bottom: 20px;
    a {
      height: 40px;
      width: 40px;
    }
  }
}

.motif-tab-fullwidth {
  width: 100%;
}
.motif-tab-content {
  padding: 24px 0;
}
.back-button {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;

  &:hover {
    gap: 12px;
    margin-left: -4px;
  }
  .motif-icon {
    height: 20px;
    width: 20px;
  }
}
.motif-tooltip-content {
  .motif-tooltip {
    max-width: min(600px, 80vw);
    white-space: pre-wrap !important;
    word-break: break-word;
    .motif-tooltip-wrapper {
      max-height: 95vh;
      overflow: auto;
    }
  }
}
.MuiTable-root {
  tr.MuiTableRow-root {
    background-color: var(--card--bg-color) !important;
    &.MuiTableRow-head {
      background: var(--Neutrals-Dark-theme-50, #2e2e38) !important;
    }
  }
  .MuiTableCell-root {
    padding-bottom: 0.8rem;
  }
  .dataGridCellValue {
    text-overflow: ellipsis;
    word-break: break-word;
    --max-width: 100% !important;
  }
  .subDatatable {
    padding: 24px;
    padding-bottom: 0;
  }
}

.dataTreemap {
  text-align: center;
  padding: 2px;
  text-overflow: ellipsis;
  word-break: break-word;
  --max-width: 100% !important;
}

.emptyGridData {
  display: flex;
  padding-top: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.emptyGridData_subTitle {
  color: #a4a3b1;
}

.emptyGridData img {
  max-width: 100%;
  margin-bottom: 24px;
}

.no-option-height .motif-option {
  max-height: initial;
}

.MuiTooltip-tooltip {
  background-color: white !important;
  color: black !important;

  .MuiTooltip-arrow {
    color: white !important;
  }
}
